/* eslint-disable react/prop-types */
import type { FC } from 'react';
import React, { Fragment, useState, useCallback } from 'react';

import { ModalTransition } from '@atlaskit/modal-dialog';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import type { MenuComponentProps } from './menuTypes';

type menuComponentProps = {
	menu: FC<MenuComponentProps> | React.ComponentClass<MenuComponentProps>;
};

export const MenuComponent: FC<menuComponentProps> = ({ menu: Menu }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [isOpen, setIsOpen] = useState(false);
	const [activeDialog, setActiveDialog] = useState(null);

	const onClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [setIsOpen, isOpen]);

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const closeDialog = useCallback(() => {
		setActiveDialog(null);
	}, [setActiveDialog]);

	const openDialog = useCallback(
		(dialog: any) => {
			setActiveDialog(dialog(closeDialog));
		},
		[setActiveDialog, closeDialog],
	);

	return (
		<Fragment>
			<Menu
				openDialog={openDialog}
				onClose={onClose}
				onClick={onClick}
				isOpen={isOpen}
				{...ssrPlaceholderIdProp}
			/>
			<ModalTransition>{activeDialog}</ModalTransition>
		</Fragment>
	);
};
